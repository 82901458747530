import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import "./App.css";
import "./assets/css/commonCss.css";

import { useDispatch } from "react-redux";
import { fetchSiteSettings } from "./states/actions/fetchSiteSettings";


import SignInContainer from "./containers/auth/SignInContainer";

import SignUpContainer from "./containers/auth/SignUpContainer";

import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import LiveTvContainer from "./containers/LiveTvContainer";
import SingleMovieContainer from "./containers/SingleMovieContainer";
import SingleTvShowContainer from "./containers/SingleTvShowContainer";
import Header from "./components/includes/header";
import Profile from "./containers/profile";
import Subscription from "./containers/subscription";
import Paymentinfo from "./containers/payment-info";
import MemberVerification from './containers/auth/member-verification';
import Verification from './containers/auth/verification';
import Error from './components/error';
import Home from "./containers/Home";
import TermsAndConditions from "./containers/terms-conditions";
import PrivacyPolicy from "./containers/privacy-policy";
import CookiePolicy from "./containers/cookie-policy";
import LicenseDocument from "./containers/license-document";
const authToken = localStorage.getItem("authToken");
ReactGA.initialize("G-8RVDR9K4P1");
const browserHistory = createBrowserHistory();
browserHistory.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSiteSettings());
  }, []);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<HomeContainer />} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/license-document" element={<LicenseDocument />} />
        <Route exact path="/auth/signin" element={(authToken === null || authToken === undefined) ? <SignInContainer /> : <Navigate to="/profile" />} />
        <Route exact path="/auth/signup" element={(authToken === null || authToken === undefined) ? <SignUpContainer /> : <Navigate to="/profile" />} />

        <Route
          exact
          path="/live-tv"
          element={authToken && authToken != null ? <LiveTvContainer /> : <Navigate to="/auth/signin" />}
        />
        <Route
          exact
          path="/movie/:slug"
          element={authToken && authToken != null ? <SingleMovieContainer /> : <Navigate to="/auth/signin" />}
        />
        <Route
          exact
          path="/tv-show/:slug"
          element={authToken && authToken != null ? <SingleTvShowContainer /> : <Navigate to="/auth/signin" />}
        />
        <Route path="" element={<Header />} />
        <Route
          exact
          path="/profile"
          element={authToken && authToken != null ? <Profile /> : <Navigate to="/auth/signin" />}
        />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/payment-info" element={<Paymentinfo />} />
        <Route path="/member-verification" element={<MemberVerification />} />
        <Route path="/verification/:token" element={<Verification />} />
        <Route path="*" element={<Error />} />
        <Route path="/404" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
