import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateProfileImage } from "../../states/actions/profileSettings"; 
import FormProcessingSpinner from "../../components/FormProcessingSpinner";
import Updatedsuccessfully from "./updatedsuccessfully";
import UpdateEmail from "./updateEmail";
import UpdatePassword from "./updatePassword";
import SideBar from "./side-bar";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { postData, doObjToFormData, imageValidation } from "../../helpers/helpers";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import { toast, ToastContainer } from "react-toastify";
import ImageControl from "../ImageControl";
const Profilesection = ({ member }) => {
  const [profileUpdate, setProfileUpdate] = useState(false);
  const { register, handleSubmit, watch, formState: { errors } } = useForm({ mode: 'onChange' });
  const onSubmit = async form_data => {
    setProfileUpdate(true);
    await postData("api/auth/update-profile", doObjToFormData(form_data)).then((data) => {
      console.log(data)
      setProfileUpdate(false);
      if (data.status === 1) {
        toast.success(data.msg,
          TOAST_SETTINGS
        );
      }
      else {
        toast.error(data.msg,
          TOAST_SETTINGS
        );
      }
    });
  }
  const [popup, setPopup] = useState(false);
  const [updatePopup, setUpdatePopup] = useState(false);
  const [passwordPopup, setPasswordPopup] = useState(false);

  function togglePopup() {
    setPopup(!popup);
  }
  function togglePasswordPopup() {
    setPasswordPopup(!passwordPopup);
  }

  const dispatch = useDispatch();


  const [isFormImageProcessing, setIsFormImageProcessing] = useState(false);


  const handleUpload = (e) => {
    
    const files = e.target.files[0];
    let valid = imageValidation(files);
    if (valid.error) {
        toast.error(valid.error,
            TOAST_SETTINGS
        );
    
    }
    else {
      setIsFormImageProcessing(true);
          dispatch(updateProfileImage(files));
     
    }
};
let logo = null;
function uploadLogo() {
    logo.click();
}

  return (
    <>
      <section id="profile">

        <div className="contain">
          <div className="flex">
            <div className="colL">
              <SideBar />
            </div>
            <div className="colR">
              <ToastContainer />
              <div className="inner">
                <div className="inner-content">
                  <h4>Your Profile</h4>
                  <button className="edit">
                    <i className="fa-solid fa-pen-to-square"></i>Edit
                  </button>
                  <div className="line"></div>
                </div>
                <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row formRow">

                  <div className="ico fill round user_img_box" id="ghf" style={{width:'15%'}}>
                  {member?.mem_image ? <ImageControl folder="members" src={member?.mem_image} /> : <img src="images/no-user.svg" alt='no_user' />}
                  
                  
                      </div>                 

                  <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-xx-5">
                      <div className="txtGrp">
                      <button type="button" className="webBtn smBtn uploadImg" data-upload="dp_image" data-text="Change Photo" onClick={uploadLogo} disabled={(isFormImageProcessing === true) ? 'disabled' : ''}>{(isFormImageProcessing === true) ? <i className="spinner"></i> : 'Change Photo'}</button>
                                <input 
                                    type="file" 
                                    name="profile_image" 
                                    id="profile_image" 
                                    className="uploadFile"
                                    onChange={handleUpload}
                                    accept="image/*"
                                    ref={(file) => (logo = file)}
                                />
                      </div>
                    </div>

                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-xx-5">
                      <div className="txtGrp">
                        <label htmlFor="">First Name</label>
                        <input
                          type="text"
                          name="fname"
                          id="fname"
                          className="txtBox"
                          placeholder="Json"
                          defaultValue={member?.mem_fname}
                          {...register("fname", {
                            pattern: {
                              value: /^[a-zA-Z][a-zA-Z ]*$/,
                              message: 'Invalid value',
                            },
                            required: "Required"
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="fname"
                          render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-xx-5">
                      <div className="txtGrp">
                        <label htmlFor="">Last Name</label>
                        <input
                          type="text"
                          name="lname"
                          id="lname"
                          className="txtBox"
                          placeholder="Crown"
                          defaultValue={member?.mem_lname}
                          {...register("lname", {
                            pattern: {
                              value: /^[a-zA-Z][a-zA-Z ]*$/,
                              message: 'Invalid value',
                            },
                            required: "Required"
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="lname"
                          render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                      </div>
                    </div>
                    <div className="" style={{ display: "flex", alignItems: "center" }}>
                      <div className="bTn">
                        <button
                          type="submit"
                          className="webBtn smBtn"
                          style={{
                            height: "41px", marginTop
                              : "22px"
                          }}
                          disabled={(profileUpdate === true) ? 'disabled' : ''}
                        > {(profileUpdate === true) ? <><i className="spinner"></i></> : 'Update'}</button>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                      <div className="txtGrp">
                        <label htmlFor="">Email ID</label>
                        <input
                          type="email"
                          name=""
                          id=""
                          className="txtBox"
                          placeholder="Jsoncrown123@yahoo.com"
                          readOnly
                          defaultValue={member?.mem_email}
                        />
                      </div>
                      {/* <div
                        className="update popBtn"
                        data-popup="update_email"
                        onClick={togglePopup}
                      >
                        <p>Update Email</p>
                        <div className="line1"></div>
                      </div> */}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6bcol-xs-6 col-xx-6">
                      <div className="txtGrp">
                        <label htmlFor="">Password</label>
                        <input
                          type="password"
                          value="...."
                          id="myInput"
                          className="txtBox"
                        />
                        <div className="eye" >
                          <i
                            className="fa-solid fa-eye-slash"
                            id="togglePassword"
                          ></i>
                        </div>
                      </div>
                      <div
                        className="update popBtn"
                        data-popup="changepassword"
                        onClick={togglePasswordPopup}
                      >
                        <p>Change Password</p>
                        <div className="line"></div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {popup === true ? (
        <>
          <UpdateEmail
            togglePopup={togglePopup}
            setUpdatePopup={setUpdatePopup}
            member={member}
          />

        </>
      ) : (
        ""
      )}
      {passwordPopup === true ? (
        <UpdatePassword togglePopup={togglePasswordPopup} setPasswordPopup={setPasswordPopup} setUpdatePopup={setUpdatePopup} />
      ) : (
        ""
      )}
      {updatePopup === true ? (
        <Updatedsuccessfully setUpdatePopup={setUpdatePopup} />
      ) : (
        ""
      )}
    </>
  );
};
export default Profilesection;
