import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import SideBar from "./side-bar";
import SubscriptionBlk from "./subscription-block";
import AddNewSubscription from './add-new-subscription';

const Subscripsection = ({ member, siteSettings }) => {
  const subscription = member?.subscription;
  const [popup, setPopup] = useState(false);
  return (
    <>
      <section id="profile">
        <div className="contain">
          <div className="flex">
            <div className="colL">
              <SideBar />
            </div>
            <div className="colR">
              <div className="inner">
                <div className="inner-content">
                  <h4>Subscription</h4>
                  <div className="lined"></div>
                </div>


                {
                  popup !== undefined && popup?.id > 0 && parseInt(subscription?.plan_id) !== parseInt(popup?.id) ?
                    <AddNewSubscription siteSettings={siteSettings} plan={popup} setPopup={setPopup} subscription={subscription} />
                    :
                    <SubscriptionBlk siteSettings={siteSettings} setPopup={setPopup} subscription={member?.subscription} />
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Subscripsection;
