import React from "react";
import { Link } from "react-router-dom";
import Image from "../ImageControl";
import Text from "../Text";
import StaticImage from "../StaticImage";
import ImageControl from "../ImageControl";
import { getBackgroundImageUrl } from "../../helpers/helpers";

const FooterHome = ({ siteSettings }) => {
  return (
    <footer className="new_footer" style={{
      backgroundImage: `url(${getBackgroundImageUrl(siteSettings?.site_footer_logo)})`
    }}>
      <div className="contain">
        <div className="flexRow flex">
          <div className="col">
            <div className="footerLogo">
              <Link to="/">
                <ImageControl src={siteSettings?.site_logo} folder="images" alt={siteSettings?.site_name} />
              </Link>
            </div>
            <p>
              <Text string={siteSettings?.site_footer_text} />
            </p>
            <div className="br_between"></div>
            <h5>
              Follow us at
            </h5>
            <ul className="social flex">
              {
                siteSettings?.site_facebook != '' ?

                  <li>
                    <a href={siteSettings.site_facebook} target="_blank">
                      <StaticImage src="social-facebook.svg" />
                    </a>
                  </li>
                  :
                  ""
              }
              {
                siteSettings?.site_instagram != '' ?
                  <li>
                    <a href={siteSettings.site_instagram} target="_blank">
                      <StaticImage src="social-instagram.svg" />
                    </a>
                  </li>
                  :
                  ""
              }
              {
                siteSettings?.site_youtube != '' ?
                  <li>
                    <a href={siteSettings.site_youtube} target="_blank">
                      <StaticImage src="social-youtube.svg" />
                    </a>
                    {/* <a href={`https://wa.me/${siteSettings.site_whatsapp}`}>
                  <StaticImage src="social-youtube.svg" />
                </a> */}
                  </li>
                  :
                  ""
              }
              {
                siteSettings?.site_twitter != '' ?
                  <li>
                    <a href={siteSettings.site_twitter} target="_blank">
                      <StaticImage src="social-twitter.svg" />
                    </a>
                  </li>
                  :
                  ""
              }

            </ul>
          </div>
          <div className="col">
            <h5>
              Resources
            </h5>
            <ul className="lst">
              <li>
                <Link to="/terms-and-conditions">
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/cookie-policy">Cookie Policy</Link>
              </li>
              <li>
                <Link to="/license-document">FSSAI License document</Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <h5>
              Call us on
            </h5>
            <ul className="lst infoLst">
              <li>
                <a href={`tel:${siteSettings.site_phone}`}>
                  <Text string={siteSettings.site_phone} />
                </a>
              </li>
            </ul>
            <div className="br_between"></div>
            <h5>
              Write to us on
            </h5>
            <ul className="lst infoLst">
              <li>
                <a href={`mailto:${siteSettings.site_email}`}>
                  <Text string={siteSettings.site_email} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright relative">
        <div className="contain">
          <div className="inner">
            <p>
              <Text string={siteSettings.site_copyright} />
            </p>
            <ul className="smLst flex">
              <li>
                <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterHome;
