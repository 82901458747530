import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../states/actions/movieDetail";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useScrollTop from "../hooks/useScrollTop";
import StaticImage from '../components/StaticImage';
import ImageControl from '../components/ImageControl';
import { NavLink, useParams } from 'react-router-dom';
import { ReactNetflixPlayer } from 'react-netflix-player';
import TvItems from '../components/tvItems';

export default function SingleMovieContainer() {
    const [popup, setPopup] = useState({
        item: null,
        show: false,
        video: null
    });
    const showPopup = (item, video, type = "video") => {
        setPopup({ ...popup, item: item, show: true, video: video, type: type });
        // alert(!popup);
    }
    const closePopup = () => {
        setPopup({ ...popup, item: null, show: false, video: null, type: "video" });
        // alert(!popup);
    }
    const { slug } = useParams();
    const data = useSelector((state) => state.movieDetail.content);
    const isLoading = useSelector((state) => state.movieDetail.isLoading);
    console.log(isLoading)
    const dispatch = useDispatch();
    const { content, site_settings } = data;

    useEffect(() => {
        dispatch(fetchData(slug));
    }, []);
    useEffect(() => {
        document.body.classList.add('liveTvBody');
    }, []);

    useDocumentTitle(data.page_title);
    useScrollTop();
    const siteSettings = useSelector(
        (state) => state.fetchSiteSettings.siteSettings
    );
    const isSiteLoading = useSelector((state) => state.fetchSiteSettings.isLoading);
    const member = siteSettings?.member;
    if (isLoading || isSiteLoading) return <LoadingScreen isLoading={isLoading} />;
    // console.log(siteSettings)
    if (parseInt(member?.mem_verified) === 0) {
        return window.location.replace("/member-verification");
    }
    if (member?.subscription === undefined || member?.subscription === null || member?.subscription === '') {
        return window.location.replace("/subscription");
    }

    const movie = data?.movie;
    const related_movies = data?.related_movies;
    // console.log(related_movies)
    return (
        <>
            {!isLoading && (
                <div>
                    {/* <Header siteSettings={site_settings} /> */}
                    <main className="liveMain">

                        <section id="banner_tv" className="movie-detail">

                            <div className="">

                                <div className="item">

                                    <div className="image">

                                        <ImageControl src={movie?.cover} folder="movies" />

                                    </div>

                                    <div className="overlay4"></div>

                                    <div className="flexDv">

                                        <div className="contain">

                                            <div className="inner">

                                                <div className="overlay5"></div>

                                                <div className="flex">

                                                    <div className="image2">

                                                        <ImageControl src={movie?.image} folder="movies" />

                                                    </div>

                                                    <div className="text">

                                                        <h3>{movie?.category}</h3>

                                                        <h1>{movie?.title}</h1>

                                                        <h4>{movie?.sub_title} | {movie?.year}</h4>

                                                        <div dangerouslySetInnerHTML={{ __html: movie?.detail }} />

                                                        <div className="bTn">

                                                            <NavLink to="#!" className="webBtn" onClick={() => showPopup(movie, movie?.video)}><StaticImage src="livetv/Group-20027.png" />Watch Now</NavLink>

                                                            <NavLink to="#!" className="webBtn" onClick={() => showPopup(movie, movie?.trailer, "trailer")}><StaticImage src="livetv/Group-20027.png" />Watch Trailer</NavLink>

                                                        </div>

                                                    </div>

                                                </div>
                                                {
                                                    movie?.cast?.length > 0 ?

                                                        <div className="casting">

                                                            <h2>CASTING</h2>

                                                            <div className="flex">
                                                                {movie?.cast?.map((cast_item, index) => {
                                                                    return (

                                                                        <div className="col" key={index}>

                                                                            <div className="inner1">

                                                                                <div className="icon">

                                                                                    <ImageControl src={cast_item?.image} folder="movies/cast" />

                                                                                </div>

                                                                                <div className="text1">

                                                                                    <h3>{cast_item?.name}</h3>

                                                                                    <h5>{cast_item?.role}</h5>

                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    );
                                                                })}


                                                            </div>

                                                        </div>
                                                        :
                                                        ""
                                                }

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </section>


                        {
                            related_movies !== undefined && related_movies?.length > 0 ?

                                <section id="popular_movies" className="related-movies">

                                    <div className="contain">

                                        <h3><strong>Related</strong> MOVIES</h3>

                                        <TvItems items={related_movies} item_folder="movies" link="movie" />

                                    </div>

                                </section>
                                :
                                ""
                        }
                    </main>
                    {
                        popup?.item !== null && popup?.item !== undefined ?
                            <section className="popup big-popup video_popup" data-popup="video_player" style={{ display: "block" }}>
                                <div className="tableDv">
                                    <div className="tableCell">
                                        <div className="_inner">
                                            {
                                                popup?.type === "trailer" ? "" :

                                                    <div className="crosBtn" onClick={closePopup}>
                                                        <div className="title">
                                                            <h3>Back</h3>
                                                        </div>
                                                    </div>
                                            }
                                            <div id="video">
                                                {
                                                    popup?.type === "trailer" ?

                                                        <ReactNetflixPlayer title={popup?.item?.title} extraInfoMedia={popup?.item?.title + " - " + popup?.item?.category} subTitle={popup?.item?.sub_title} src={popup?.video} playerLanguage="en" backButton={() => setPopup({ ...popup, item: null, show: false })} autoControllCloseEnabled="true" autoPlay="true" />
                                                        :
                                                        <div dangerouslySetInnerHTML={{ __html: popup?.video }} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            :
                            ""
                    }
                </div>
            )
            }
        </>
    )
}
