import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../states/actions/liveTV";
import Header from "../components/includes/header";
import LoadingScreen from "../components/LoadingScreen";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useScrollTop from "../hooks/useScrollTop";
import StaticImage from "../components/StaticImage";
import ImageControl from "../components/ImageControl";
import { NavLink } from "react-router-dom";
import Text from "../components/Text";
import OwlTvItems from "../components/owlTvItems";
import OwlTopTvItems from "../components/owlTopTvItems";
import TvItems from "../components/tvItems";
import * as paths from "../constants/paths";
import { ReactNetflixPlayer } from "react-netflix-player";

export default function LiveTvContainer() {
  const [popup, setPopup] = useState({
    item: null,
    show: false,
  });
  const showPopup = (item) => {
    setPopup({ ...popup, item: item, show: true });
    // alert(!popup);
  };
  const closePopup = () => {
    setPopup({ ...popup, item: null, show: false });
    // alert(!popup);
  };

  const data = useSelector((state) => state.liveTV.content);
  const isLoading = useSelector((state) => state.liveTV.isLoading);
  const dispatch = useDispatch();
  const { content, site_settings } = data;

  useEffect(() => {
    dispatch(fetchData());
  }, []);
  useEffect(() => {
    document.body.classList.add("liveTvBody");
  }, []);

  useDocumentTitle(data.page_title);
  useScrollTop();
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const isSiteLoading = useSelector((state) => state.fetchSiteSettings.isLoading);
  const member = siteSettings?.member;
  if (isLoading || isSiteLoading) return <LoadingScreen isLoading={isLoading} />;
  // console.log(siteSettings)
  if (parseInt(member?.mem_verified) === 0) {
    return window.location.replace("/member-verification");
  }
  const featuredPost = data?.featuredPost;
  const movies = data?.movies;
  const popular_movies = data?.popular_movies;
  const tv_shows = data?.tv_shows;
  const popular_tv_shows = data?.popular_tv_shows;

  const trending_movies = data?.trending_movies;
  const new_releases = data?.new_releases;
  const new_releases_tv_shows = data?.new_releases_tv_shows;
  const trending_tv_shows = data?.trending_tv_shows;
  const top_tv_shows = data?.top_tv_shows;


  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      {!isLoading && (
        <div>
          <Header siteSettings={site_settings} className="tv_header" />
          {
            member?.subscription !== undefined && member?.subscription !== null && member?.subscription?.id > 0 ?

              <main className="liveMain">

                {featuredPost !== null &&
                  featuredPost !== "" &&
                  featuredPost !== undefined ? (
                  <section id="banner_tv" className="banner_tv">
                    <div className="">
                      <div className="item">
                        <div className="image">
                          <ImageControl
                            src={featuredPost?.cover}
                            folder={featuredPost?.folder}
                            alt={featuredPost?.title}
                          />
                        </div>
                        <div className="overlay"></div>
                        <div className="flexDv">
                          <div className="contain">
                            <div className="content">
                              <p>
                                {featuredPost?.category} ‧ {featuredPost?.sub_title}{" "}
                                ‧ {featuredPost?.year}
                              </p>
                              <div className="flex">
                                <div className="netflix-logo">
                                  <StaticImage
                                    src="livetv/netflix.png"
                                    alt="netflix"
                                  />
                                </div>
                                <h4>{featuredPost?.type}</h4>
                              </div>
                              <h1>{featuredPost?.title}</h1>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: featuredPost?.detail,
                                }}
                              />
                              <div className="banner_btn_w_blk">
                                <NavLink
                                  to={featuredPost?.link}
                                  data-popup="video_player"
                                  className="webBtn popBtn"
                                // onClick={() => showPopup(featuredPost)}
                                >
                                  <StaticImage src="livetv/Group-20027.png" />
                                  Watch Now
                                </NavLink>
                                <NavLink
                                  to={featuredPost?.link}
                                  data-popup="video_player"
                                  className="webBtn popBtn white"

                                >
                                  <i className="fa fa-info-circle"></i>
                                  More Info
                                </NavLink>
                              </div>


                            </div>

                            {/* <div className="trending_shows">
                              <h3>
                                <strong>TRENDING</strong> LIVE TV
                              </h3>
                              <OwlTvItems
                                items={movies}
                                item_folder="movies"
                                items_arr={tv_shows}
                                item_arr_folder="tv_shows"
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                ) : (
                  ""
                )}
                <section className="poster_movies_shows">
                  <div className="contain">
                    <div className="trending_shows">
                      <h3>
                        <strong>TOP</strong> 10 TV Shows Today
                      </h3>
                      <OwlTopTvItems
                        items_arr={top_tv_shows}
                        item_arr_folder="tv_shows"

                      />
                    </div>
                  </div>
                </section>
                <section id="trending_movies_shows">
                  <div className="contain">
                    <div className="trending_shows">
                      <h3>
                        <strong>New </strong> Releases
                      </h3>
                      <OwlTvItems
                        items={new_releases}
                        item_folder="movies"
                        items_arr={new_releases_tv_shows}
                        item_arr_folder="tv_shows"
                      />
                    </div>
                  </div>
                </section>



                {trending_tv_shows?.length > 0 ? (
                  <section id="popular_movies" className="tv-shows">
                    <div className="contain">
                      <h3>
                        <strong>Trending</strong> TV SHOWS
                      </h3>
                      <TvItems
                        items={trending_tv_shows}
                        item_folder="tv_shows"
                        link="tv-show"
                      />
                    </div>
                  </section>
                ) : (
                  ""
                )}

                <section className="poster_movies_shows">
                  <div className="contain">
                    <div className="trending_shows">
                      <h3>
                        <strong>TOP</strong> 10 Movies Today
                      </h3>
                      <OwlTopTvItems
                        items={trending_movies}
                        item_folder="movies"

                      />
                    </div>
                  </div>
                </section>
                {trending_movies?.length > 0 ? (
                  <section id="popular_movies">
                    <div className="contain">
                      <h3>
                        <strong>Trending</strong> MOVIES
                      </h3>
                      <TvItems
                        items={trending_movies}
                        item_folder="movies"
                        link="movie"
                      />
                    </div>
                  </section>
                ) : (
                  ""
                )}
              </main>
              :
              <main className="liveMain subscribe_upgrade">
                <section className="innerBox">
                  <h3>Subscribe Now</h3>
                  <p>If you want to watch live stream please subscribe Now.</p>
                  <div className="bTn text-center">
                    <NavLink to="/subscription" className="webBtn colorBtn">Subscription Plans</NavLink>
                  </div>
                </section>
              </main>
          }
          {popup?.item !== null && popup?.item !== undefined ? (
            <section
              className="popup big-popup"
              data-popup="video_player"
              style={{ display: "block" }}
            >
              <div className="tableDv">
                <div className="tableCell">
                  <div className="_inner">
                    <div className="crosBtn" onClick={() => closePopup}>
                      <div className="title">
                        <h3>Back</h3>
                      </div>
                    </div>
                    <div id="video">
                      {/* <ReactNetflixPlayer
                        title={popup?.item?.title}
                        src={popup?.item?.link}
                        playerLanguage="en"
                        backButton={() =>
                          setPopup({ ...popup, item: null, show: false })
                        }
                        autoControllCloseEnabled="true"
                        autoPlay="true"
                      /> */}
                      <div dangerouslySetInnerHTML={{ __html: popup?.item?.video }} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
          {/* <Footer siteSettings={site_settings} /> */}
        </div>
      )}
    </>
  );
}
