import React from "react";
import Header from "../components/includes/header";
import Paysection from "../components/dashboard-pages/payment-section";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from '../components/LoadingScreen';
const Paymentinfo = () => {
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const isLoading = useSelector((state) => state.fetchSiteSettings.isLoading);
  const member = siteSettings?.member;
  useDocumentTitle('Payment Information');
  if (isLoading) return <LoadingScreen isLoading={isLoading} />;
  // console.log(siteSettings)
  if (parseInt(member?.mem_verified) === 0) {
    return window.location.replace("/member-verification");
  }
  return (
    <>
      <style>{"body { background-color: #121b22; }"}</style>
      <Header siteSettings={siteSettings} />
      <Paysection />
    </>
  );
};

export default Paymentinfo;
