import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { postData, doObjToFormData } from "../../helpers/helpers";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import { toast, ToastContainer } from "react-toastify";

const UpdatePassword = ({ togglePopup, setPasswordPopup, setUpdatePopup }) => {
  const [passwordChange, setPasswordChange] = useState(false);
  const { register, handleSubmit, watch, formState: { errors } } = useForm({ mode: 'onChange' });
  const onSubmit = data => {
    handlePasswordChange(data)
  }
  async function handlePasswordChange(form_data) {
    // console.log(form_data)
    setPasswordChange(true);
    await postData("api/auth/update-password", doObjToFormData(form_data)).then((data) => {
      console.log(data)
      setPasswordChange(false);
      if (data.status === 1) {
        setPasswordPopup(false)
        setUpdatePopup(true)
        toast.success(data.msg,
          TOAST_SETTINGS
        );
      }
      else {
        toast.error(data.msg,
          TOAST_SETTINGS
        );
      }
    });
  }
  return (
    <>

      <section className="cmnpopup popup">
        <div className="tableDv">
          <div className="tableCell">
            <div className="contain">
              <div className="inner">
                <ToastContainer />
                <div className="crosBtn" onClick={togglePopup}></div>
                <h2>Change Your password</h2>
                <p>
                  Protect Your account with unique password atleats 6 characters
                  long.
                </p>
                <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row formRow">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                      <label for="">Current Password</label>
                      <div className="txtGrp">
                        <input
                          type="password"
                          name="old_password"
                          id="old_password"
                          className="txtBox"
                          placeholder="..."
                          autoComplete="off"
                          {...register("old_password", { required: "Required" })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="old_password"
                          render={({ message }) => <p className='error flex'><i className="fi-warning"></i> {message}</p>}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                      <label for="">New Password</label>
                      <div className="txtGrp">
                        <input
                          type="password"
                          name="new_password"
                          id="new_password"
                          className="txtBox"
                          placeholder="6-60 characters"
                          {...register("new_password", {
                            required: "Required", pattern: {
                              value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]{8,}$/,
                              message: "The password should contain at least 8 characters, that contain at least one lowercase letter, one uppercase letter and one numeric digit."
                            }
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="new_password"
                          render={({ message }) => <p className='error flex'><i className="fi-warning"></i> {message}</p>}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                      <label for="">Confirm Password</label>
                      <div className="txtGrp">
                        <input
                          type="password"
                          name="confirm_password"
                          id="confirm_password"
                          className="txtBox"
                          placeholder="6-60 characters"
                          {...register("confirm_password", {
                            required: "Required",
                            validate: (val) => {
                              if (watch('new_password') != val) {
                                return "Your passwords do no match";
                              }
                            },
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="confirm_password"
                          render={({ message }) => <p className='error flex'><i className="fi-warning"></i> {message}</p>}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bTns">
                    <button
                      type="submit"
                      className="webBtn green"
                      // onClick={() => setUpdatePopup(true)}
                      disabled={(passwordChange === true) ? 'disabled' : ''}
                    >
                      {(passwordChange === true) ? <><i className="spinner"></i></> : 'Update Password'}
                    </button>
                    <button className="webBtn grey" onClick={togglePopup}>
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

// #endregion

export default UpdatePassword;
