export const FETCH_HOME_CONTENT_SUCCESS = "FETCH_HOME_CONTENT_SUCCESS";
export const FETCH_HOME_CONTENT_FAILED = "FETCH_HOME_CONTENT_FAILED";

export const FETCH_WHAT_IS_HUMAN_TRAFFIK_CONTENT_SUCCESS =
  "FETCH_WHAT_IS_HUMAN_TRAFFIK_CONTENT_SUCCESS";
export const FETCH_WHAT_IS_HUMAN_TRAFFIK_CONTENT_FAILED =
  "FETCH_WHAT_IS_HUMAN_TRAFFIK_CONTENT_FAILED";

export const FETCH_WHAT_IS_SEX_TRAFFIK_CONTENT_SUCCESS =
  "FETCH_WHAT_IS_SEX_TRAFFIK_CONTENT_SUCCESS";
export const FETCH_WHAT_IS_SEX_TRAFFIK_CONTENT_FAILED =
  "FETCH_WHAT_IS_SEX_TRAFFIK_CONTENT_FAILED";

export const FETCH_FACTS_AND_STATS_CONTENT_SUCCESS =
  "FETCH_FACTS_AND_STATS_CONTENT_SUCCESS";
export const FETCH_FACTS_AND_STATS_CONTENT_FAILED =
  "FETCH_FACTS_AND_STATS_CONTENT_FAILED";

export const FETCH_POLICY_AND_LEGISLATION_CONTENT_SUCCESS =
  "FETCH_POLICY_AND_LEGISLATION_CONTENT_SUCCESS";
export const FETCH_POLICY_AND_LEGISLATION_CONTENT_FAILED =
  "FETCH_POLICY_AND_LEGISLATION_CONTENT_FAILED";

export const FETCH_HELP_AND_RECOURCES_CONTENT_SUCCESS =
  "FETCH_HELP_AND_RECOURCES_CONTENT_SUCCESS";
export const FETCH_HELP_AND_RECOURCES_CONTENT_FAILED =
  "FETCH_HELP_AND_RECOURCES_CONTENT_FAILED";

export const FETCH_TRAFFIK_AND_SEX_CONTENT_SUCCESS =
  "FETCH_TRAFFIK_AND_SEX_CONTENT_SUCCESS";
export const FETCH_TRAFFIK_AND_SEX_CONTENT_FAILED =
  "FETCH_TRAFFIK_AND_SEX_CONTENT_FAILED";

export const FETCH_NATIONAL_DIRECTORY_CONTENT_SUCCESS =
  "FETCH_NATIONAL_DIRECTORY_CONTENT_SUCCESS";
export const FETCH_NATIONAL_DIRECTORY_CONTENT_FAILED =
  "FETCH_NATIONAL_DIRECTORY_CONTENT_FAILED";
export const SEARCH_ORGANIZATIONS = "SEARCH_ORGANIZATIONS";
export const SEARCH_ORGANIZATIONS_SUCCESS = "SEARCH_ORGANIZATIONS_SUCCESS";
export const SEARCH_ORGANIZATIONS_FAILED = "SEARCH_ORGANIZATIONS_FAILED";

export const FETCH_CURRENT_AFFAIRS_CONTENT_SUCCESS =
  "FETCH_CURRENT_AFFAIRS_CONTENT_SUCCESS";
export const FETCH_CURRENT_AFFAIRS_CONTENT_FAILED =
  "FETCH_CURRENT_AFFAIRS_CONTENT_FAILED";

export const FETCH_PROJECT_UNITE_CONTENT_SUCCESS =
  "FETCH_PROJECT_UNITE_CONTENT_SUCCESS";
export const FETCH_PROJECT_UNITE_CONTENT_FAILED =
  "FETCH_PROJECT_UNITE_CONTENT_FAILED";

export const FETCH_OUR_SPONSORS_CONTENT_SUCCESS =
  "FETCH_OUR_SPONSORS_CONTENT_SUCCESS";
export const FETCH_OUR_SPONSORS_CONTENT_FAILED =
  "FETCH_OUR_SPONSORS_CONTENT_FAILED";

export const FETCH_DONATE_NOW_CONTENT_SUCCESS =
  "FETCH_DONATE_NOW_CONTENT_SUCCESS";
export const FETCH_DONATE_NOW_CONTENT_FAILED =
  "FETCH_DONATE_NOW_CONTENT_FAILED";
export const SAVE_DONATION =
  "SAVE_DONATION";
export const SAVE_DONATION_SUCCESS =
  "SAVE_DONATION_SUCCESS";
export const SAVE_DONATION_FAILED =
  "SAVE_DONATION_FAILED";

export const FETCH_CORPORATE_PARTNERS_CONTENT_SUCCESS =
  "FETCH_CORPORATE_PARTNERS_CONTENT_SUCCESS";
export const FETCH_CORPORATE_PARTNERS_CONTENT_FAILED =
  "FETCH_CORPORATE_PARTNERS_CONTENT_FAILED";

export const FETCH_START_A_FUNDRAISER_CONTENT_SUCCESS =
  "FETCH_START_A_FUNDRAISER_CONTENT_SUCCESS";
export const FETCH_START_A_FUNDRAISER_CONTENT_FAILED =
  "FETCH_START_A_FUNDRAISER_CONTENT_FAILED";

export const FETCH_NEAR_EVENTS_CONTENT_SUCCESS =
  "FETCH_NEAR_EVENTS_CONTENT_SUCCESS";
export const FETCH_NEAR_EVENTS_CONTENT_FAILED =
  "FETCH_NEAR_EVENTS_CONTENT_FAILED";
export const SEARCH_NEARBY_EVENTS = "SEARCH_NEARBY_EVENTS";
export const SEARCH_NEARBY_EVENTS_SUCCESS = "SEARCH_NEARBY_EVENTS_SUCCESS";
export const SEARCH_NEARBY_EVENTS_FAILED = "SEARCH_NEARBY_EVENTS_FAILED";

export const FETCH_DONATE_PAY_CONTENT_SUCCESS =
  "FETCH_DONATE_PAY_CONTENT_SUCCESS";
export const FETCH_DONATE_PAY_CONTENT_FAILED =
  "FETCH_DONATE_PAY_CONTENT_FAILED";

export const FETCH_RESCUE_STORIES_CONTENT_SUCCESS =
  "FETCH_RESCUE_STORIES_CONTENT_SUCCESS";
export const FETCH_RESCUE_STORIES_CONTENT_FAILED =
  "FETCH_RESCUE_STORIES_CONTENT_FAILED";

export const FETCH_RESCUE_STORY_DETAIL_CONTENT_SUCCESS =
  "FETCH_RESCUE_STORY_DETAIL_CONTENT_SUCCESS";
export const FETCH_RESCUE_STORY_DETAIL_CONTENT_FAILED =
  "FETCH_RESCUE_STORY_DETAIL_CONTENT_FAILED";

export const FETCH_BLOG_DETAIL_CONTENT_SUCCESS =
  "FETCH_BLOG_DETAIL_CONTENT_SUCCESS";
export const FETCH_BLOG_DETAIL_CONTENT_FAILED =
  "FETCH_BLOG_DETAIL_CONTENT_FAILED";

export const FETCH_NEWS_DETAIL_CONTENT_SUCCESS =
  "FETCH_NEWS_DETAIL_CONTENT_SUCCESS";
export const FETCH_NEWS_DETAIL_CONTENT_FAILED =
  "FETCH_NEWS_DETAIL_CONTENT_FAILED";

export const FETCH_SHARE_STORY_CONTENT_SUCCESS =
  "FETCH_SHARE_STORY_CONTENT_SUCCESS";
export const FETCH_SHARE_STORY_CONTENT_FAILED =
  "FETCH_SHARE_STORY_CONTENT_FAILED";

export const FETCH_NEWSAPI_NEWS_SUCCESS = "FETCH_NEWSAPI_NEWS_SUCCESS";
export const FETCH_NEWSAPI_NEWS_FAILED = "FETCH_NEWSAPI_NEWS_FAILED";

export const FETCH_CONTACT_US_CONTENT_SUCCESS =
  "FETCH_CONTACT_US_CONTENT_SUCCESS";
export const FETCH_CONTACT_US_CONTENT_FAILED =
  "FETCH_CONTACT_US_CONTENT_FAILED";
export const SAVE_CONTACT_MESSAGE = "SAVE_CONTACT_MESSAGE";
export const SAVE_CONTACT_MESSAGE_VALIDATOIN_ERROR =
  "SAVE_CONTACT_MESSAGE_VALIDATOIN_ERROR";
export const SAVE_CONTACT_MESSAGE_SUCCESS = "SAVE_CONTACT_MESSAGE_SUCCESS";
export const SAVE_CONTACT_MESSAGE_FAILED = "SAVE_CONTACT_MESSAGE_FAILED";

export const FETCH_SIGN_UP_CONTENT_SUCCESS = "FETCH_SIGN_UP_CONTENT_SUCCESS";
export const FETCH_SIGN_UP_CONTENT_FAILED = "FETCH_SIGN_UP_CONTENT_FAILED";
export const CREATE_ACCOUNT_MESSAGE = "CREATE_ACCOUNT_MESSAGE";
export const CREATE_ACCOUNT_MESSAGE_VALIDATOIN_ERROR =
  "CREATE_ACCOUNT_MESSAGE_VALIDATOIN_ERROR";
export const CREATE_ACCOUNT_MESSAGE_SUCCESS = "CREATE_ACCOUNT_MESSAGE_SUCCESS";
export const CREATE_ACCOUNT_MESSAGE_FAILED = "CREATE_ACCOUNT_MESSAGE_FAILED";

export const FETCH_SIGN_IN_CONTENT_SUCCESS = "FETCH_SIGN_IN_CONTENT_SUCCESS";
export const FETCH_SIGN_IN_CONTENT_FAILED = "FETCH_SIGN_IN_CONTENT_FAILED";
export const SIGN_IN_MESSAGE = "SIGN_IN_MESSAGE";
export const SIGN_IN_MESSAGE_VALIDATOIN_ERROR =
  "SIGN_IN_MESSAGE_VALIDATOIN_ERROR";
export const SIGN_IN_MESSAGE_SUCCESS = "SIGN_IN_MESSAGE_SUCCESS";
export const SIGN_IN_MESSAGE_FAILED = "SIGN_IN_MESSAGE_FAILED";
export const FETCH_LIVE_TV_CONTENT_SUCCESS = "FETCH_LIVE_TV_CONTENT_SUCCESS";
export const FETCH_LIVE_TV_CONTENT_FAILED = "FETCH_LIVE_TV_CONTENT_FAILED";
export const FETCH_MOVIE_DETAIL_CONTENT_SUCCESS = "FETCH_MOVIE_DETAIL_CONTENT_SUCCESS";
export const FETCH_MOVIE_DETAIL_CONTENT_FAILED = "FETCH_MOVIE_DETAIL_CONTENT_FAILED";
export const FETCH_TV_SHOW_DETAIL_CONTENT_SUCCESS = "FETCH_TV_SHOW_DETAIL_CONTENT_SUCCESS";
export const FETCH_TV_SHOW_DETAIL_CONTENT_FAILED = "FETCH_TV_SHOW_DETAIL_CONTENT_FAILED";

export const FETCH_SITE_SETTINGS = "FETCH_SITE_SETTINGS";
export const FETCH_SITE_SETTINGS_SUCCESS = "FETCH_SITE_SETTINGS_SUCCESS";
export const FETCH_SITE_SETTINGS_FAILED = "FETCH_SITE_SETTINGS_FAILED";

export const SAVE_SUBSCRIBE_MESSAGE = "SAVE_SUBSCRIBE_MESSAGE";
export const SAVE_SUBSCRIBE_MESSAGE_SUCCESS = "SAVE_SUBSCRIBE_MESSAGE_SUCCESS";
export const SAVE_SUBSCRIBE_MESSAGE_FAILED = "SAVE_SUBSCRIBE_MESSAGE_FAILED";

export const FETCH_TERMS_CONTENT_SUCCESS = "FETCH_TERMS_CONTENT_SUCCESS";
export const FETCH_TERMS_CONTENT_FAILED = "FETCH_TERMS_CONTENT_FAILED";

export const FETCH_PRIVACY_POLICY_CONTENT_SUCCESS = "FETCH_PRIVACY_POLICY_CONTENT_SUCCESS";
export const FETCH_PRIVACY_POLICY_CONTENT_FAILED = "FETCH_PRIVACY_POLICY_CONTENT_FAILED";

export const FETCH_COOKIE_POLICY_CONTENT_SUCCESS = "FETCH_COOKIE_POLICY_CONTENT_SUCCESS";
export const FETCH_COOKIE_POLICY_CONTENT_FAILED = "FETCH_COOKIE_POLICY_CONTENT_FAILED";

export const FETCH_LICENSE_CONTENT_SUCCESS = "FETCH_LICENSE_CONTENT_SUCCESS";
export const FETCH_LICENSE_CONTENT_FAILED = "FETCH_LICENSE_CONTENT_FAILED";

export const FETCH_PROFILE_SETTINGS = "FETCH_PROFILE_SETTINGS";
    export const FETCH_PROFILE_SETTINGS_SUCCESS = "FETCH_PROFILE_SETTINGS_SUCCESS";
    export const FETCH_PROFILE_SETTINGS_FAILED = "FETCH_PROFILE_SETTINGS_FAILED";
    export const PROFILE_IMAGE_SETTINGS = "PROFILE_IMAGE_SETTINGS";
    export const PROFILE_IMAGE_SETTINGS_SUCCESS = "PROFILE_IMAGE_SETTINGS_SUCCESS";
    export const PROFILE_IMAGE_SETTINGS_FAILED = "PROFILE_IMAGE_SETTINGS_FAILED";
    export const PROFILE_SETTINGS = "PROFILE_SETTINGS";
    export const PROFILE_SETTINGS_SUCCESS = "PROFILE_SETTINGS_SUCCESS";
    export const PROFILE_SETTINGS_FAILED = "PROFILE_SETTINGS_FAILED";
    export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
    export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
    export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
    export const MEMBER_QUESTION = "MEMBER_QUESTION";
    export const MEMBER_QUESTION_SUCCESS = "MEMBER_QUESTION_SUCCESS";
    export const MEMBER_QUESTION_FAILED = "MEMBER_QUESTION_FAILED";
    export const FETCH_NOTIFICATION = "FETCH_NOTIFICATION";
    export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";
    export const FETCH_NOTIFICATION_FAILED = "FETCH_NOTIFICATION_FAILED";