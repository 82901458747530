import http from "../../helpers/http";
import { doObjToFormData } from "../../helpers/helpers";

import {
    FETCH_MOVIE_DETAIL_CONTENT_SUCCESS,
    FETCH_MOVIE_DETAIL_CONTENT_FAILED
} from "../actions/actionTypes";

export const fetchData = (slug) => (dispatch) => {
    http
        .post("movie-details", doObjToFormData({ slug: slug }))
        .then(({ data }) => {
            if (data?.status === 1) {
                dispatch({
                    type: FETCH_MOVIE_DETAIL_CONTENT_SUCCESS,
                    payload: data
                });
            }
            else {
                window.location.href = "/404"
            }

        })
        .catch((error) => {
            dispatch({
                type: FETCH_MOVIE_DETAIL_CONTENT_FAILED,
                payload: error
            });
        });
};
