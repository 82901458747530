import {
  FETCH_NEAR_EVENTS_CONTENT_SUCCESS,
  FETCH_NEAR_EVENTS_CONTENT_FAILED,
  SEARCH_NEARBY_EVENTS,
  SEARCH_NEARBY_EVENTS_SUCCESS,
  SEARCH_NEARBY_EVENTS_FAILED
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isFormProcessing: false,
  events: []
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_NEAR_EVENTS_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
        events: payload.events
      };
    case FETCH_NEAR_EVENTS_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
        events: []
      };
    case SEARCH_NEARBY_EVENTS:
      return {
        ...state,
        isFormProcessing: true,
        events: []
      };
    case SEARCH_NEARBY_EVENTS_SUCCESS:
      return {
        ...state,
        isFormProcessing: false,
        events: payload.events
      };
    case SEARCH_NEARBY_EVENTS_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload,
        events: []
      };
    default:
      return state;
  }
}
