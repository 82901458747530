import {
  FETCH_SIGN_UP_CONTENT_SUCCESS,
  FETCH_SIGN_UP_CONTENT_FAILED,
  CREATE_ACCOUNT_MESSAGE,
  CREATE_ACCOUNT_MESSAGE_SUCCESS,
  CREATE_ACCOUNT_MESSAGE_FAILED
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isFormProcessing: false,
  formData: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    applicant: "Influencer"
  }
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_SIGN_UP_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload
      };
    case FETCH_SIGN_UP_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case CREATE_ACCOUNT_MESSAGE:
      return {
        ...state,
        isFormProcessing: true
      };
    case CREATE_ACCOUNT_MESSAGE_SUCCESS:
      console.log("hi")
      localStorage.setItem("authToken", payload.authToken);
      return {
        ...state,
        formData: {
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirmPassword: "",
          applicant: "Influencer"
        },
        isFormProcessing: false
      };
    case CREATE_ACCOUNT_MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    default:
      return state;
  }
}
