import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../states/actions/tvShowDetail";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useScrollTop from "../hooks/useScrollTop";
import StaticImage from '../components/StaticImage';
import ImageControl from '../components/ImageControl';
import { NavLink, useParams } from 'react-router-dom';
import { ReactNetflixPlayer } from 'react-netflix-player';
import TvItems from '../components/tvItems';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const options = {
    autoplay: false,
    nav: true,
    dots: true,
    navText: ['<i className="fi-chevron-left"></i>', '<i className="fi-chevron-right"></i>'],
    loop: true,
    margin: 20,
    smartSpeed: 1000,
    autoplayTimeout: 8000,
    autoplayHoverPause: true,
    items: 4,
    responsive: {
        0: {
            items: 1,
            autoplay: true,
            autoHeight: true,
            dots: false,
            nav: false,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 4,
        },
    }
};
export default function SingleTvShowContainer() {
    const [popup, setPopup] = useState({
        item: null,
        show: false,
        video: null
    });
    const [tvTabs, setTvTab] = useState({
        item: null,
    });
    const showPopup = (item, video, type = "video") => {
        setPopup({ ...popup, item: item, show: true, video: video, type: type });
        // alert(!popup);
    }
    const closePopup = () => {
        setPopup({ ...popup, item: null, show: false, video: null, type: "video" });
        // alert(!popup);
    }
    const showTab = (item) => {
        setTvTab({ ...tvTabs, item: item });
        // alert(!popup);
    }
    const { slug } = useParams();
    const data = useSelector((state) => state.tvShowDetail.content);
    const isLoading = useSelector((state) => state.tvShowDetail.isLoading);
    console.log(isLoading)
    const dispatch = useDispatch();
    const { content, site_settings } = data;

    useEffect(() => {
        dispatch(fetchData(slug));
    }, []);
    useEffect(() => {
        document.body.classList.add('liveTvBody');
    }, []);

    useDocumentTitle(data.page_title);
    useScrollTop();
    const siteSettings = useSelector(
        (state) => state.fetchSiteSettings.siteSettings
    );
    const isSiteLoading = useSelector((state) => state.fetchSiteSettings.isLoading);
    const member = siteSettings?.member;
    if (isLoading || isSiteLoading) return <LoadingScreen isLoading={isLoading} />;
    // console.log(siteSettings)
    if (parseInt(member?.mem_verified) === 0) {
        return window.location.replace("/member-verification");
    }
    if (member?.subscription === undefined || member?.subscription === null || member?.subscription === '') {
        return window.location.replace("/subscription");
    }
    const tv_show = data?.tv_show;
    const related_tv_shows = data?.related_tv_shows;
    const seasons = data?.seasons;
    const all_seasons = data?.all_seasons;
    console.log(popup)
    return (
        <>
            <LoadingScreen isLoading={isLoading} />
            {!isLoading && (
                <div>
                    {/* <Header siteSettings={site_settings} /> */}
                    <main className="liveMain">



                        <section id="banner_tv" className="tv_detail">

                            <div className="">

                                <div className="item">

                                    <div className="image">

                                        <ImageControl src={tv_show?.cover} folder="tv_shows" />

                                    </div>

                                    <div className="overlay4"></div>

                                    <div className="flexDv">

                                        <div className="contain">

                                            <div className="inner">

                                                <div className="overlay5"></div>

                                                <div className="flex1">

                                                    <div className="text">

                                                        <h3>{tv_show?.category}</h3>

                                                        <h1>{tv_show?.title}</h1>

                                                        <h4>{tv_show?.sub_title} | {tv_show?.year}</h4>

                                                        <div dangerouslySetInnerHTML={{ __html: tv_show?.detail }} />


                                                        <div className="bTn">

                                                            <NavLink to="#!" className="webBtn" onClick={() => showPopup(tv_show, tv_show?.trailer, "trailer")}><StaticImage src="livetv/Group-20027.png" />Watch Trailer</NavLink>

                                                            {/* <NavLink to="#!" className="webBtn" onClick={() => showPopup(movie, movie?.trailer)}><StaticImage src="livetv/Group-20027.png" />Watch Trailer</NavLink> */}

                                                        </div>

                                                    </div>

                                                    {/* <div className="image3">

                                                        <div className="overlay8"></div>

                                                        <img src="images/d1.png" alt="" />

                                                        <div className="content1">

                                                            <h3>WATCH LATEST EPISODE</h3>

                                                            <h5>Episode 1 . 22 Oct, 2018</h5>

                                                            <div className="bar1">

                                                                <div className="progress">

                                                                    <div className="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0"
                                                                        aria-valuemax="100" style="width: 77%;">

                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div> */}

                                                </div>


                                                {/* <div className="tab">
                                                    {all_seasons?.map((season, index) => {
                                                        return (
                                                            <button className={index === 0 ? "tablink blue" : "tablink"} onClick={()=>showTab(season)}>{season?.name}</button>
                                                        );
                                                    })}

                                                </div> */}

                                                <div id="all" className="remodel">
                                                    {all_seasons?.map((season, index) => {
                                                        return (
                                                            <>
                                                                <h3>{season?.name}</h3>

                                                                <OwlCarousel className="owl-theme" {...options}>
                                                                    {season?.episodes?.map((episode, index) => {
                                                                        return (

                                                                            <NavLink to="#!" className="item1" onClick={() => showPopup(episode, episode?.video)}>

                                                                                <div className="image1">

                                                                                    <ImageControl src={episode?.image} folder="tv_shows/episodes" />

                                                                                </div>

                                                                                <div className="text">

                                                                                    <h4>{episode?.title}</h4>

                                                                                    {/* <h5>Episode - 1</h5> */}

                                                                                </div>

                                                                            </NavLink>
                                                                        );
                                                                    })}


                                                                </OwlCarousel>
                                                            </>
                                                        );
                                                    })}



                                                    {
                                                        related_tv_shows !== undefined && related_tv_shows?.length > 0 ?

                                                            <div className="mt">

                                                                <h3><strong>RELATED</strong>TV SHOWS</h3>

                                                                <TvItems items={related_tv_shows} item_folder="tv_shows" link="tv-show" className="item1" imageClassName="image1" sectionClassName="seasons owl-theme" />

                                                            </div>
                                                            :
                                                            ""
                                                    }

                                                </div>





                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </section>



                    </main>
                    {
                        popup?.item !== null && popup?.item !== undefined ?
                            <section className="popup big-popup video_popup" data-popup="video_player" style={{ display: "block" }}>
                                <div className="tableDv">
                                    <div className="tableCell">
                                        <div className="_inner">
                                            {
                                                popup?.type === 'trailer' ? "" :

                                                    <div className="crosBtn" onClick={closePopup}>
                                                        <div className="title">
                                                            <h3>Back</h3>
                                                        </div>
                                                    </div>
                                            }
                                            <div id="video">
                                                {
                                                    popup?.type === 'trailer' ?

                                                        <ReactNetflixPlayer title={popup?.item?.title} extraInfoMedia={popup?.item?.title + " - " + popup?.item?.category} subTitle={popup?.item?.sub_title} src={popup?.video} playerLanguage="en" backButton={() => setPopup({ ...popup, item: null, show: false })} autoControllCloseEnabled="true" autoPlay="true" />
                                                        :
                                                        <div dangerouslySetInnerHTML={{ __html: popup?.video }} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            :
                            ""
                    }
                </div>
            )
            }
        </>
    )
}
