import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData, createAccount } from "../../states/actions/signUp";
import { Link, NavLink } from "react-router-dom";
import StaticImage from "../../components/StaticImage";
import LoadingScreen from "../../components/LoadingScreen";
import ImageControl from "../../components/ImageControl";
import FormProcessingSpinner from "../../components/FormProcessingSpinner";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import Text from "../../components/Text";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { getBackgroundImageUrlThumb } from "../../helpers/helpers";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

const SignUpContainer = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm({ mode: 'onChange' });
  const password = useRef({});
  password.current = watch("password", "");
  const watchAllFields = watch();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.signUp.content);
  const isLoading = useSelector((state) => state.signUp.isLoading);
  const isFormProcessing = useSelector(
    (state) => state.signUp.isFormProcessing
  );
  const { content, site_settings } = data;




  useEffect(() => {
    dispatch(fetchData());
  }, []);


  const onSubmit = (data) => {
    dispatch(createAccount(data));
  };

  useDocumentTitle(data.page_title);
  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      {!isLoading && (
        <div>
          <ToastContainer />
          <main common logon>
            <section className="form-blk form-sign-up new_logon">
              <div className="contain">
                <div className="colR log_blk">
                  <div className="image">
                    <Link to="/">
                      <img src="/images/logo-new.png" alt="" />
                    </Link>
                  </div>
                  <div className="inner">
                    <h2>Signup</h2>
                    <form method="POST" onSubmit={handleSubmit(onSubmit)}>
                      <div className="row formRow">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                          <div className="txtGrp">
                            {/* <h5>First Name</h5> */}
                            <h5>
                              <input
                                type="text"
                                name="firstName"
                                className="txtBox"
                                placeholder="Enter first name"
                                {...register("firstName", {
                                  pattern: {
                                    value: /^[a-zA-Z][a-zA-Z ]*$/,
                                    message: 'Invalid value',
                                  },
                                  required: "Required"
                                })}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="firstName"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                              />
                            </h5>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                          <div className="txtGrp">
                            {/* <h5>Last Name</h5> */}
                            <h5>
                              <input
                                type="text"
                                name="lastName"
                                className="txtBox"
                                placeholder="Enter last name"
                                {...register("lastName", {
                                  pattern: {
                                    value: /^[a-zA-Z][a-zA-Z ]*$/,
                                    message: 'Invalid value',
                                  },
                                  required: "Required"
                                })}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="lastName"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                              />
                            </h5>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                          <div className="txtGrp">
                            {/* <h5>Email</h5> */}
                            <h5>
                              <input
                                type="text"
                                name="email"
                                className="txtBox"
                                placeholder="Enter email"
                                {...register("email", {
                                  required: "Required", pattern: {
                                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                                    message: "Email format is not valid!"
                                  }
                                })}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="email"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                              />
                            </h5>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                          <div className="txtGrp">
                            {/* <h5>Password</h5> */}
                            <h5>
                              <input
                                type="password"
                                name="password"
                                className="txtBox"
                                placeholder="Enter password"
                                {...register("password", {
                                  required: "Required", pattern: {
                                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]{8,}$/,
                                    message: "The password should contain at least 8 characters, that contain at least one lowercase letter, one uppercase letter and one numeric digit."
                                  }
                                })}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="password"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                              />
                            </h5>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                          <div className="txtGrp">
                            {/* <h5>Confirm Password</h5> */}
                            <h5>
                              <input
                                type="password"
                                name="confirmPassword"
                                className="txtBox"
                                placeholder="Enter password again"
                                {...register("confirmPassword", {
                                  required: "Required",
                                  validate: (val) => {
                                    if (watch('password') != val) {
                                      return "Your passwords do no match";
                                    }
                                  },
                                })}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="confirmPassword"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                              />
                            </h5>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                          <div className="txtGrp">
                            <h5>Select applicant</h5>
                            <div className="flex radioLst radioSizeLst">
                              <div className="innerCol">
                                <input
                                  type="radio"
                                  name="applicant"
                                  value="Influencer"
                                  className="hide"
                                  id="i_i"
                                  {...register("applicant", {
                                    required: "Required",
                                  })}
                                />
                                <label
                                  htmlFor="i_i"
                                  className={
                                    watchAllFields?.applicant == "Influencer"
                                      ? "donate_btn_active"
                                      : ""
                                  }
                                >
                                  Influencer
                                </label>
                              </div>
                              <div className="innerCol">
                                <input
                                  type="radio"
                                  name="applicant"
                                  value="Street Team"
                                  className="hide"
                                  id="s_t"
                                  {...register("applicant", {
                                    required: "Required",
                                  })}
                                />
                                <label
                                  htmlFor="s_t"
                                  className={
                                    watchAllFields?.applicant == "Street Team"
                                      ? "donate_btn_active"
                                      : ""
                                  }
                                >
                                  Street Team
                                </label>
                              </div>
                              <div className="innerCol">
                                <input
                                  type="radio"
                                  name="applicant"
                                  value="Volunteer"
                                  className="hide"
                                  id="vol"
                                  {...register("applicant", {
                                    required: "Required",
                                  })}
                                />
                                <label
                                  htmlFor="vol"
                                  className={
                                    watchAllFields?.applicant == "Volunteer"
                                      ? "donate_btn_active"
                                      : ""
                                  }
                                >
                                  Volunteer
                                </label>
                              </div>
                              <div className="innerCol">
                                <input
                                  type="radio"
                                  name="applicant"
                                  value="Non Profit Organization"
                                  className="hide"
                                  id="n_p"
                                  {...register("applicant", {
                                    required: "Required",
                                  })}
                                />
                                <label
                                  htmlFor="n_p"
                                  className={
                                    watchAllFields?.applicant ==
                                      "Non Profit Organization"
                                      ? "donate_btn_active"
                                      : ""
                                  }
                                >
                                  Non Profit Organization
                                </label>
                              </div>
                            </div>
                            <ErrorMessage
                              errors={errors}
                              name="applicant"
                              render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                          <button
                            type="submit"
                            className="webBtn lgBtn"
                            disabled={isFormProcessing ? true : false}
                          >
                            Create Account
                            <FormProcessingSpinner
                              isFormProcessing={isFormProcessing}
                            />
                          </button>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                          <div className="haveAccount">
                            <span className="gray-color">
                              Already have an account ?
                            </span>
                            <Link
                              to="/auth/signin"
                              className="semi"
                              id="member"
                            >
                              Sign In
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                          <div className="haveAccount privacy">
                            <span className="gray-color">
                              By proceeding to create your account you are
                              agreeing to our
                            </span>
                            <NavLink to="/terms-and-conditions" className="semi" id="member">
                              Terms of Service{" "}
                            </NavLink>
                            <span className="gray-color"> and </span>
                            <NavLink to="/privacy-policy" className="semi" id="member">
                              Privacy Policy
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      )}
    </>
  );
};

export default SignUpContainer;
