import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../states/actions/terms_and_conditions";
import LoadingScreen from "../components/LoadingScreen";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useScrollTop from "../hooks/useScrollTop";
import StaticImage from "../components/StaticImage";
import ImageControl from "../components/ImageControl";
import { NavLink } from "react-router-dom";
import Text from "../components/Text";
import OwlTvItems from "../components/owlTvItems";
import OwlTopTvItems from "../components/owlTopTvItems";
import TvItems from "../components/tvItems";
import * as paths from "../constants/paths";
import { ReactNetflixPlayer } from "react-netflix-player";
import HeaderHome from "../components/includes/header-home";
import FooterHome from "../components/shared/Footer-home";

export default function TermsAndConditions() {
    const data = useSelector((state) => state.terms_and_conditions.content);
    const isLoading = useSelector((state) => state.terms_and_conditions.isLoading);
    const dispatch = useDispatch();
    const { content, site_settings, details } = data;

    useEffect(() => {
        dispatch(fetchData());
    }, []);
    useEffect(() => {
        document.body.classList.add("terms_and_conditionsBody");
    }, []);

    useDocumentTitle(data.page_title);
    useScrollTop();
    const siteSettings = useSelector(
        (state) => state.fetchSiteSettings.siteSettings
    );
    const isSiteLoading = useSelector((state) => state.fetchSiteSettings.isLoading);
    if (isLoading || isSiteLoading) return <LoadingScreen isLoading={isLoading} />;
    return <>
        <LoadingScreen isLoading={isLoading} />
        {!isLoading && (
            <div>
                <HeaderHome siteSettings={siteSettings} className="tv_header" />
                <main className="index terms_bg">
                    <section className="textual_page">
                        <div className="contain">
                            <div className="text_blk">
                                <div className="sec_heading">
                                    <h2>{content?.heading}</h2>
                                </div>
                                <div className="ckEditor">
                                    <div>
                                        <Text string={details} parse={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <FooterHome siteSettings={site_settings} />
            </div>

        )}
    </>;
}
