import http from "../../helpers/http";

import {
    FETCH_SITE_SETTINGS,
    FETCH_SITE_SETTINGS_SUCCESS,
    FETCH_SITE_SETTINGS_FAILED,
} from "./actionTypes";

export const fetchSiteSettings = () => (dispatch) => {
    dispatch({
        type: FETCH_SITE_SETTINGS,
        payload: null
    });
    http
        .get("site-settings")
        .then(({ data }) => {
          
            if (data?.settings?.member === null && data?.not_logged !== true) {

                localStorage.clear();
                window.location.replace("/auth/signin");
            }
            if (data?.settings?.member?.token !== 'null' && data?.settings?.member?.error === 1 && data?.settings?.member?.errorType === "invalid_token") {
                localStorage.clear();
                window.location.replace("/auth/signin");
            }
            else if (data?.settings?.member === false) {
                localStorage.clear();
                window.location.replace("/auth/signin");
            }
            dispatch({
                type: FETCH_SITE_SETTINGS_SUCCESS,
                payload: data.settings
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_SITE_SETTINGS_FAILED,
                payload: error
            });
        });
};

