import React from "react";
import Error404 from "./includes/error-404";
import useDocumentTitle from "../hooks/useDocumentTitle";

function Error() {
    const error = {
        num: "404",
        title: "Page not found",
        para: "Let's pretend ..... !! You never saw that. Go back to the Homepage to find out more.",
        btn: "Back to the website",
        btn_link: "/",
    };
    useDocumentTitle('404 - TraffixnSex');
    return (
        <>
            <Error404 data={error} />
        </>
    );
}

export default Error;
