import React from "react";
import { NavLink } from "react-router-dom";
import SideBar from "./side-bar";

const Paysection = () => {
  return (
    <>
      <section id="profile">
        <div className="contain">
          <div className="flex">
            <div className="colL">
              <SideBar />
            </div>
            <div className="colR">
              <div className="inner">
                <div className="pay-inner">
                  <h4>Payment Info</h4>
                  <p>All the recurring payment and one time Payment</p>
                  <div className="lines"></div>
                </div>
                <form action="">
                  <div className="box">
                    <div className="bcontent">
                      <div className="box-block">
                        <div className="icons">
                          <i className="fa-solid fa-check"></i>
                        </div>
                        <div className="account_text">
                          <div className="image">
                            <img src="images/hsbc.png" alt="" />
                          </div>
                          <p>**** **** **** 5674</p>
                          <ul>
                            <li>Expires On 11/2024</li>
                            <li>
                              Next Billing Date <span>16 Nov 2023</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="box-block2">
                        <p>$25/Month</p>
                        <ul>
                          <li>$299 Billed Annually</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="box">
                    <div className="bcontent">
                      <div className="box-block">
                        <div className="icons">
                          <i className="fa-solid fa-check"></i>
                        </div>
                        <div className="account_text">
                          <div className="image">
                            <img src="images/hsbc.png" alt="" />
                          </div>
                          <p>**** **** **** 5674</p>
                          <ul>
                            <li>Expires On 11/2024</li>
                            <li>
                              Next Billing Date <span>16 Nov 2023</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="box-block2">
                        <a href="" className="donate_btn">
                          Set as default
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="bTns">
                    <button type="submit" className="donate_btn">
                      + Add New Card
                    </button>
                  </div>
                </form>
                <div className="payment_history">
                  <h4>Payment History</h4>
                  <ul>
                    <li>
                      <h6>Date</h6>
                    </li>
                    <li>
                      <h6>Description & Service Period</h6>
                    </li>
                    <li>
                      <h6>Payment Method</h6>
                    </li>
                    <li>
                      <h6>Total</h6>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      {" "}
                      <h5>16 Nov 22</h5>
                    </li>
                    <li>
                      <h5>Creator Pro (Professional Plan)</h5>
                      <h6>16 Nov 22 - 16 Nov 23</h6>
                    </li>
                    <li>
                      <img src="images/hsbc.png" alt="" />
                      <h6>**** 5674 (Card)</h6>
                    </li>
                    <li>
                      <h5>$300.00</h5>
                      <h6>$260.00($40 Tax)</h6>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      {" "}
                      <h5>16 Nov 22</h5>
                    </li>
                    <li>
                      <h5>Creator Pro (Professional Plan)</h5>
                      <h6>16 Nov 22 - 16 Nov 23</h6>
                    </li>
                    <li>
                      <img src="images/hsbc.png" alt="" />
                      <h6>**** 5674 (Card)</h6>
                    </li>
                    <li>
                      <h5>$300.00</h5>
                      <h6>$260.00($40 Tax)</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Paysection;
