import React, { useState } from 'react'
import { useForm } from "react-hook-form";


import { Elements } from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';
import AddSubscriptionBlk from './addSubscriptionBlk';
export default function AddNewSubscription({ siteSettings, plan, setPopup, subscription }) {
    const [checkoutError, setCheckoutError] = useState();
    const [isProcessing, setProcessing] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm({ mode: 'onChange' });
    const handleCardDetailsChange = ev => {
        ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
    };

    let stripePromise = ''
    if (siteSettings.site_stripe_type === 1 || siteSettings.site_stripe_type === '1') {
        stripePromise = loadStripe(siteSettings?.site_stripe_testing_api_key);
    }
    else if (siteSettings.site_stripe_type === 0 || siteSettings.site_stripe_type === '0') {
        stripePromise = loadStripe(siteSettings?.site_stripe_live_api_key);
    }


    return (
        <>
            <h4>You have selected <strong>${plan?.plan_price} {plan?.plan_name}</strong></h4>
            {
                subscription !== undefined && subscription !== null && subscription?.id > 0 ?
                    <h5>Your Current Subscription is: {subscription?.plan_row?.plan_type} Plan</h5>
                    :
                    ""
            }
            <Elements stripe={stripePromise}>
                <AddSubscriptionBlk register={register} errors={errors} handleCardDetailsChange={handleCardDetailsChange} handleSubmit={handleSubmit} checkoutError={checkoutError} setProcessing={setProcessing} isProcessing={isProcessing} setCheckoutError={setCheckoutError} setPopup={setPopup} plan={plan} subscription={subscription} />
            </Elements>
        </>
    )
}
