import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import LoadingScreen from '../LoadingScreen';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ImageControl from "../ImageControl";
const HeaderHome = ({ className, verification_page = false }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [dropToggle, setDropToggle] = useState(false);
  const ToggleDrop = () => {
    setDropToggle(!dropToggle)
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
    
  );
  useEffect(() => {
    console.log(siteSettings)

  }, [siteSettings]);
  const isLoading = useSelector((state) => state.fetchSiteSettings.isLoading);
  if (isLoading) return <LoadingScreen isLoading={isLoading} />;
  const member = siteSettings?.member;
  // if (verification_page === false && parseInt(member?.mem_verified) === 0) {
  //   return window.location.replace("/member-verification");
  // }
  const Logout = () => {
    localStorage.clear();
    window.location.href = "/auth/signin";
  };
  
  return (
    <>
      <header className={scrollPosition > 100 ? "dashboard_header fix new_home_header " + className : "dashboard_header new_home_header " + className}>
        <div className="contain">
          <div className="header_flx">
            <div className="dash_logo ">
              <NavLink to="/">
                <ImageControl src={siteSettings?.site_logo} folder="images" alt={siteSettings?.site_name} />
              </NavLink>
            </div>
            <div className="col_nav">
              {
                member?.mem_id > 0 ?
                  <ul id="nav">
                    <li className="drop">
                      <a href="#" className="icon" onClick={ToggleDrop}>
                      {member?.mem_image ? <ImageControl folder="members" src={member?.mem_image} /> : <img src="images/no-user.svg" alt='no_user' />}

                        <span>{member?.mem_fname + " " + member?.mem_lname}</span>
                      </a>
                      <ul className={dropToggle ? "sub active" : "sub"}>
                        <li>
                          <NavLink to="/profile">Profile</NavLink>{" "}
                        </li>
                        <li>
                          <NavLink to="/subscription">Subscription</NavLink>{" "}
                        </li>
                        {/* <li>
                      <NavLink to="/payment-info">Payment Info</NavLink>{" "}
                    </li> */}
                        <li>
                          <NavLink to="/live-tv">Live Streaming</NavLink>{" "}
                        </li>
                        <li>
                          <NavLink to="#!" onClick={Logout}>Logout</NavLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  :
                  <ul id="cta">
                    <li className>
                      <Link to="/auth/signin" className="webBtn greenBtn">
                        Sign in
                      </Link>
                    </li>
                  </ul>
              }
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default HeaderHome;
