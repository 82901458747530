import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData, saveSubscriber } from "../states/actions/home";
import FooterHome from "../components/shared/Footer-home";
// import Header from "../components/shared/Header";
import HeaderHome from "../components/includes/header-home";
import LoadingScreen from "../components/LoadingScreen";
import { Link, NavLink } from "react-router-dom";
import ImageControl from "../components/ImageControl";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Text from "../components/Text";
import { getBackgroundImageUrl } from "../helpers/helpers";
import ReactOwlCarousel from "react-owl-carousel";
import useScrollTop from "../hooks/useScrollTop";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import FormProcessingSpinner from "../components/FormProcessingSpinner";
import { ToastContainer } from "react-toastify";

const Home = () => {
  const option = {
    autoplay: true,
    nav: false,
    // navText: ['<i className="fi-chevron-left"></i>','<i className="fi-chevron-right"></i>'],
    loop: true,
    dots: false,
    center: true,
    smartSpeed: 1000,
    autoplayTimeout: 10000,
    autoplayHoverPause: true,
    autoWidth: false,
    // autoheight: false,
    margin: 20,
    responsive: {
      0: {
        items: 1,
        center: false,
        autoWidth: false
      },
      767: {
        items: 3
      },
      1200: {
        items: 4
      }
    }
  };
  const data = useSelector((state) => state.home.content);
  const isLoading = useSelector((state) => state.home.isLoading);
  const isFormProcessing = useSelector((state) => state.home.isFormProcessing);
  const dispatch = useDispatch();
  const { content, site_settings, faqs, home_logos, gallery } = data;

  useEffect(() => {
    dispatch(fetchData());
  }, []);
  useEffect(() => {
    document.body.classList.remove('liveTvBody');
  }, []);
  useDocumentTitle(data.page_title);
  useScrollTop();
  // console.log(content);
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = {
      email,
    };
    dispatch(saveSubscriber(formData));
  };
  // console.log(data)
  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      {!isLoading && (
        <div>
          <HeaderHome site_Settings={site_settings} />
          <main className="index">
            <section className="new_home_banner" style={{
              backgroundImage: `url(${getBackgroundImageUrl(content?.image1)})`
            }}>
              <div className="contain">
                <div className="cntnt">
                  <Text string={content?.banner_desc} parse={true} />
                  <NavLink to={content?.banner_button_link} className="webBtn greenBtn">{content?.banner_button_title}</NavLink>
                </div>
              </div>
            </section>
            {/* banner */}
            <section className="new_slider_sec">
              <div className="contain">
                <div className="cntnt text-center">
                  <Text string={content?.sec1_desc} parse={true} />
                </div>

              </div>
              {
                gallery?.length > 0 ?

                  <ReactOwlCarousel
                    className="owl-carousel owl-theme new_slider"
                    {...option}
                  >
                    {gallery?.map((gal) => {
                      return (
                        <div className="item" key={gal.id}>
                          <Link to="" className="image">
                            <img src={gal?.image} alt={gal?.title} />
                          </Link>
                        </div>
                      );
                    })}
                  </ReactOwlCarousel>
                  :
                  ""
              }
              <div className="contain">
                <div className="block_join_dim">
                  <div className="flex">
                    <div className="colL">
                      <Text string={content?.sec3_desc} parse={true} />
                      <NavLink to={content?.section3_button_link} className="webBtn greenBtn">{content?.section3_button_text}</NavLink>
                    </div>
                    <div className="colR">
                      <div className="image">
                        <ImageControl src={content?.image2} folder="images" isThumb={true} />
                      </div>
                      <div className="image">
                        <ImageControl src={content?.image3} folder="images" isThumb={true} />
                      </div>
                      <div className="image">
                        <ImageControl src={content?.image4} folder="images" isThumb={true} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sign_up_new">
                  <div className="inner_sign_up_new" style={{
                    backgroundImage: `url(${getBackgroundImageUrl(content?.image5)})`
                  }}>
                    <div className="left_image">
                      <ImageControl src={content?.image6} folder="images" isThumb={true} />
                    </div>
                    <div className="right_image">
                      <ImageControl src={content?.image7} folder="images" isThumb={true} />
                    </div>
                    <div className="cntnt">
                      <div className="trafic_logo">
                        <ImageControl src={content?.image8} folder="images" isThumb={true} />
                      </div>
                      <Text string={content?.sec4_desc} parse={true} />
                    </div>
                  </div>
                  {
                    home_logos?.length > 0 ?
                      <div className="logos_lst">

                        {home_logos?.map((home_logo, i) => {
                          return (
                            <div className="logo_inner_icon" key={i}>
                              <ImageControl src={home_logo?.image} folder="images" isThumb={true} alt={home_logo?.title} />
                            </div>
                          );
                        })}
                      </div>
                      :
                      ""
                  }
                  <div className="text-center dim_text_text">
                    <NavLink to={content?.section4_button_link} className="webBtn greenBtn">{content?.section4_button_text}</NavLink>
                    <p>{content?.section4_button_below_text}</p>
                  </div>
                  <div className="last_cntnt text-center">
                    <Text string={content?.sec4_desc1} parse={true} />
                  </div>
                </div>
              </div>
            </section>

            <section className="faq_sec">
              <div className="contain">
                {
                  faqs?.length > 0 ?
                    <>
                      <div className="text-center">
                        <h2>{content?.sec5_heading}</h2>
                      </div>
                      <div className="faq_blk_sep">
                        {faqs?.map((faq) => {
                          return (
                            <Accordion key={faq.id}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                              >
                                <Typography
                                  className="heading_faq"
                                >
                                  {faq?.question}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography className="description_faq"><Text string={faq?.answer} parse={true} /></Typography>
                              </AccordionDetails>
                            </Accordion>
                          );
                        })}

                      </div>
                    </>
                    :
                    ""
                }
                <div className="last_cntnt text-center">
                  <ToastContainer />
                  <Text string={content?.subscribe_desc} parse={true} />
                  <form action="" onSubmit={handleSubmit}>
                    <input type="text" placeholder="Enter your email ID" onChange={(e) => setEmail(e.target.value)} value={email} />
                    <button tyep="submit">Get Started<FormProcessingSpinner
                      isFormProcessing={isFormProcessing}
                    /></button>
                  </form>
                </div>
              </div>
            </section>
          </main>
          <FooterHome siteSettings={site_settings} />
        </div>
      )}
    </>
  );
};

export default Home;
