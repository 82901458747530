import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ImageControl from './ImageControl';
import StaticImage from './StaticImage';
import { NavLink } from 'react-router-dom';
const options = {
    autoplay: false,
    nav: true,
    dots: false,
    navText: ['<i class="fa-solid fa-chevron-left"></i>', '<i class="fa-solid fa-chevron-right"></i>'],
    loop: true,
    margin: 10,
    smartSpeed: 1000,
    autoplayTimeout: 8000,
    autoplayHoverPause: true,
    items: 5,
    responsive: {
        0: {
            items: 1,
            autoplay: true,
            autoHeight: true,
            dots: false,
            nav: false,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 3,
        },
        1200: {
            items: 5,
        },
    }
};
export default function TvItems({ items, item_folder, link, className = "item hover_item_outer", imageClassName = "image", sectionClassName = "owl-theme" }) {
    return (
        <>
            <OwlCarousel className={sectionClassName} {...options}>
                {items?.map((item, index) => {
                    return (

                        <NavLink to={"/" + link + "/" + item?.slug} className={className} key={index}>
                            <div className={imageClassName}>
                                <ImageControl src={item?.image} folder={item_folder} alt={item?.title} />
                            </div>
                            <div className="tv_hover_grid">
                                <div className="inner_text">
                                    <ul>
                                        <li>
                                            <button>
                                                <img src="/images/play-fill.png" alt="" />
                                            </button>
                                        </li>
                                        {/* <li>
                                            <button>
                                                <img src="/images/thumbs-up.png" alt="" />
                                            </button>
                                        </li> */}
                                    </ul>
                                    <h4>{item?.title}</h4>
                                    <h5>{item?.sub_title}</h5>
                                </div>
                            </div>
                            {/* <div className="text">
                                <h4>{item?.title}</h4>
                                <h5>{item?.sub_title}</h5>
                            </div> */}
                        </NavLink>
                    );
                })}

            </OwlCarousel>
        </>
    )
}
