import { doObjToFormData } from "../../helpers/helpers";
import http from "../../helpers/http";
import { toast } from "react-toastify";
import Text from "../../components/Text";
import { TOAST_SETTINGS } from "../../utils/siteSettings";

import {
    FETCH_TERMS_CONTENT_SUCCESS,
    FETCH_TERMS_CONTENT_FAILED,
    FETCH_PRIVACY_POLICY_CONTENT_FAILED,
    FETCH_PRIVACY_POLICY_CONTENT_SUCCESS,
    FETCH_COOKIE_POLICY_CONTENT_SUCCESS,
    FETCH_COOKIE_POLICY_CONTENT_FAILED,
    FETCH_LICENSE_CONTENT_FAILED,
    FETCH_LICENSE_CONTENT_SUCCESS
} from "../actions/actionTypes";

export const fetchData = () => (dispatch) => {
    http
        .get("terms-and-conditions")
        .then(({ data }) => {
            dispatch({
                type: FETCH_TERMS_CONTENT_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_TERMS_CONTENT_FAILED,
                payload: error
            });
        });
};
export const fetchPrivacyData = () => (dispatch) => {
    http
        .get("privacy-policy")
        .then(({ data }) => {
            dispatch({
                type: FETCH_PRIVACY_POLICY_CONTENT_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_PRIVACY_POLICY_CONTENT_FAILED,
                payload: error
            });
        });
};
export const fetchCookieData = () => (dispatch) => {
    http
        .get("cookie-policy")
        .then(({ data }) => {
            dispatch({
                type: FETCH_COOKIE_POLICY_CONTENT_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_COOKIE_POLICY_CONTENT_FAILED,
                payload: error
            });
        });
};
export const fetchLicenseData = () => (dispatch) => {
    http
        .get("license-document")
        .then(({ data }) => {
            dispatch({
                type: FETCH_LICENSE_CONTENT_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_LICENSE_CONTENT_FAILED,
                payload: error
            });
        });
};
