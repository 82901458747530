import { doObjToFormData } from "../../helpers/helpers";
import http from "../../helpers/http";
import { toast } from "react-toastify";
import Text from "../../components/Text";
import { TOAST_SETTINGS } from "../../utils/siteSettings";

import {
  FETCH_SIGN_IN_CONTENT_SUCCESS,
  FETCH_SIGN_IN_CONTENT_FAILED,
  SIGN_IN_MESSAGE,
  SIGN_IN_MESSAGE_SUCCESS,
  SIGN_IN_MESSAGE_FAILED
} from "../actions/actionTypes";

export const fetchData = () => (dispatch) => {
  http
    .get("sign-in")
    .then(({ data }) => {
      dispatch({
        type: FETCH_SIGN_IN_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SIGN_IN_CONTENT_FAILED,
        payload: error
      });
    });
};

export const signin = (formData) => (dispatch) => {
  dispatch({
    type: SIGN_IN_MESSAGE,
    payload: null
  });
  http
    .post("auth/sign-in-account", doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success("Sign In successfull.", TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/profile");
        }, 2000);
        dispatch({
          type: SIGN_IN_MESSAGE_SUCCESS,
          payload: data
        });
      } else {
        if (data.validationErrors) {
          toast.error(
            <Text string={data.validationErrors} parse={true} />,
            TOAST_SETTINGS
          );
        }
        if (data.msg) {
          toast.error(data.msg, TOAST_SETTINGS);
        }
        dispatch({
          type: SIGN_IN_MESSAGE_FAILED,
          payload: null
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SIGN_IN_MESSAGE_FAILED,
        payload: error
      });
    });
};

export const googleSignin = (credentials) => (dispatch) => {
  // dispatch({
  //   type: SIGN_IN_MESSAGE,
  //   payload: null
  // });
  http
    .post("auth/google-sign-in-account", doObjToFormData(credentials))
    .then(({ data }) => {
      if (data.status) {
        toast.success("Sign In successfull.", TOAST_SETTINGS);
        dispatch({
          type: SIGN_IN_MESSAGE_SUCCESS,
          payload: data
        });
      } else {
        if (data.msg) {
          toast.error(data.msg, TOAST_SETTINGS);
        }
        dispatch({
          type: SIGN_IN_MESSAGE_FAILED,
          payload: null
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SIGN_IN_MESSAGE_FAILED,
        payload: error
      });
    });
};
