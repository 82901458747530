import {
  FETCH_NATIONAL_DIRECTORY_CONTENT_SUCCESS,
  FETCH_NATIONAL_DIRECTORY_CONTENT_FAILED,
  SEARCH_ORGANIZATIONS,
  SEARCH_ORGANIZATIONS_SUCCESS,
  SEARCH_ORGANIZATIONS_FAILED
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  organizations: [],
  isFormProcessing: false
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_NATIONAL_DIRECTORY_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
        organizations: payload.organizations
      };
    case FETCH_NATIONAL_DIRECTORY_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
        organizations: []
      };
    case SEARCH_ORGANIZATIONS:
      return {
        ...state,
        isFormProcessing: true,
        organizations: []
      };
    case SEARCH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        isFormProcessing: false,
        organizations: payload.organizations
      };
    case SEARCH_ORGANIZATIONS_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload,
        organizations: []
      };
    default:
      return state;
  }
}
