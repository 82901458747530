import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Image from "../../components/ImageControl";
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from '../../components/LoadingScreen';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import { toast, ToastContainer } from "react-toastify";
import { postData } from '../../helpers/helpers';
import HeaderHome from '../../components/includes/header-home';
export default function MemberVerification() {
    const [emailVerify, setEmailVerify] = useState(false);
    const siteSettings = useSelector(
        (state) => state.fetchSiteSettings.siteSettings
    );
    const isLoading = useSelector((state) => state.fetchSiteSettings.isLoading);
    const member = siteSettings?.member;
    async function resendEmail(e) {
        setEmailVerify(true);
        var form_data = new FormData();
        form_data.append('email', true);
        await postData("api/auth/resend-email", form_data).then((data) => {
            console.log(data)
            setEmailVerify(false);
            if (data.status === 1) {

                toast.success(data.msg,
                    TOAST_SETTINGS
                );
            }
            else {
                toast.error(data.msg,
                    TOAST_SETTINGS
                );
            }
        });
    }
    useDocumentTitle('Email Verification');
    if (isLoading) return <LoadingScreen isLoading={isLoading} />;
    console.log(member)
    // if (parseInt(member?.mem_verified) === 1) {
    //     return window.location.replace("/profile");
    // }
    return <>
        <HeaderHome siteSettings={siteSettings} verification_page={true} className="dark_header" />
        <main className='terms_bg'>
            <ToastContainer />
            <section className="dashboard" id="profile">
                <div className="contain">
                    <div id="email_box">
                        <div className="contain">
                            <div className="inner">
                                <Link to="/">
                                    <Image folder="images" src={siteSettings.site_logo} classes="logo" />
                                </Link>
                                <div className="content">
                                    <p>You are almost there! We sent an email to <a href={"mailto:" + member?.mem_email} className="strong">{member?.mem_email}</a></p>
                                    <p>Just click on the link in that email to complete your sign-up. If you don't see it, you may need to <strong>check your spam</strong> folder. </p>
                                    <p>Still can't find the email?</p>
                                    <div className="bTn text-center">
                                        <NavLink to="#!" className="webBtn colorBtn" onClick={resendEmail} disabled={(emailVerify === true) ? 'disabled' : ''}>{(emailVerify === true) ? <><i className="spinner"></i><label>Sending...</label></> : 'Resend Email'}</NavLink>
                                    </div>
                                    <p>Need Help? <NavLink to="/contact" className="lnk">Contact Us</NavLink></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </>
}
