import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import store from "./states/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
// require("dotenv").config();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
<>
<Provider store={store}>
    <GoogleOAuthProvider clientId="372601941465-6gfu7hsjhija7fk1b03cq6bi32r2c9i1.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </Provider>
</>

);