import React from "react";
import { Link, NavLink } from "react-router-dom";

function Error404({ data }) {
    return (
        <>
            <section id="oops">
                <div className="flex" style={{ flexDirection: "column" }}>
                    <NavLink to="/" className="logo" style={{ margin: "0 auto 80px" }}>
                        <img src="images/traffiknsex-logo.png" alt="" />
                    </NavLink>
                    <div className="contain text-center">
                        <div className="icon">{data.num}</div>
                        <div className="inner">
                            <h4>{data.title}</h4>
                            <p>{data.para}</p>
                            <div className="btn_blk">
                                <Link to={data.btn_link} className="webBtn colorBtn">
                                    {data.btn}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Error404;
