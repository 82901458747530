import parse from "html-react-parser";
import moment from "moment";
import * as paths from "../constants/paths";
import axios from "axios";
export function doObjToFormData(obj) {
  var formData = new FormData();
  for (var key in obj) {
    if (Array.isArray(obj[key])) {
      for (let [keyv, value] of Object.entries(obj[key])) {
        formData.append(key + "[]", JSON.stringify(value));
      }
    } else {
      if (typeof obj[key] == "object") {
        formData.append(key, JSON.stringify(obj[key]));
      } else {
        formData.append(key, obj[key]);
      }
    }
  }
  return formData;
}
let project_url = '';
let project_ap_url = '';
let hostname = window.location.hostname;
if (hostname === 'localhost') {
  project_url = 'http://localhost:3000/';
  project_ap_url = "http://unitetv.net/unitetv-api/";
}

else {
  project_url = "http://unitetv.net/";
  project_ap_url = "http://unitetv.net/unitetv-api/";
}
const token = localStorage.getItem("authToken");
const post_headers = {
  "Content-Type": "application/json",
  Authorization: token,
};
export async function postData(method, data, parameters = null) {
  let u;
  parameters === null
    ? (u = project_ap_url + method)
    : (u = project_ap_url + method + "/" + parameters);

  return axios
    .post(u, data, {
      headers: post_headers,
    })
    .then((res) => {
      return res.data;
    });
}
export async function getData(method, parameters = null) {
  let u;
  parameters === null
    ? (u = project_ap_url + method)
    : (u = project_ap_url + method + "/" + parameters);

  const token = localStorage.getItem("authToken");
  const response = await fetch(u, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },

  });

  const data = await response.json();
  return data;
}
export function doFirstUpperRestLower(word) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

export function doParseHTML(string) {
  return parse(string);
}

export function getBackgroundImageUrl(src) {
  const base_api_url = paths.API_CMS_BG_IMAGES_URL;
  return base_api_url + src;
}
export function getBackgroundImageUrlThumb(src, size) {
  const base_api_url = paths.API_CMS_BG_IMAGES_URL;
  return base_api_url + size + "p_" + src;
}

export function getUploadsUrl(folder, src) {
  const base_api_url = paths.API_UPLOADS_URL;
  return base_api_url + folder + "/" + src;
}

export function newsDateFormat(date) {
  // alert(date);
  return moment(date).format("DD MMMM YYYY");
}

export function eventTimeFormat(time) {
  return moment(time, "HHmmss").format("hh:mm A");
}

export function bytesToMegaBytes(bytes) {
  return bytes / (1024 * 1024);
}
export function split_string(str, symbol) {
  var ar = str.split(symbol);
  return ar;
}

export function imageValidation(files) {
  let res = [];
  let fileSize = files.size;
  let image_type = split_string(files.type, '/');
  console.log(image_type)
  if (image_type[0] === 'image') {
    if (image_type[1] === "webp") {
      res['error'] = 'Only images(jpg,jpeg,png,svg,gif) are allowed to upload. The file type you are trying to upload is not allowed.';
      return res;
    }
    else {
      let sizeMb = bytesToMegaBytes(fileSize);
      if (sizeMb > 20) {
        res['error'] = 'The Image size is too big. Allowed size is 20MB.';
        return res;
      }
    }

  }
  else if (image_type[1] === "webp") {
    res['error'] = 'Only images(jpg,jpeg,png,svg,gif) are allowed to upload. The file type you are trying to upload is not allowed.';
    return res;
  }
  else {
    res['error'] = 'Only images(jpg,jpeg,png,svg,gif) are allowed to upload. The file type you are trying to upload is not allowed.';
    return res;
  }
  return res;
}