import {
  FETCH_HOME_CONTENT_SUCCESS,
  FETCH_HOME_CONTENT_FAILED,
  SAVE_SUBSCRIBE_MESSAGE_SUCCESS,
  SAVE_SUBSCRIBE_MESSAGE_FAILED,
  SAVE_SUBSCRIBE_MESSAGE
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  isFormProcessing: true,
  content: {},
  error: false,
  news: []
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_HOME_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload
      };
    case FETCH_HOME_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case SAVE_SUBSCRIBE_MESSAGE:
      return {
        ...state,
        isFormProcessing: true
      };
    case SAVE_SUBSCRIBE_MESSAGE_SUCCESS:
      return {
        ...state,
        news: payload.articles,
        isFormProcessing: false
      };
    case SAVE_SUBSCRIBE_MESSAGE_FAILED:
      return {
        ...state,
        news: [],
        isFormProcessing: false
      };
    default:
      return state;
  }
}
