import React from "react";
import * as paths from "../constants/paths";

const StaticImage = ({ src }) => {
  let url = paths.API_ASSETS_IMAGES_URL;
  src = `${url}${src}`;
  return (
    <>
      <img alt="Error While Loading Image" src={src} />
    </>
  );
};

export default StaticImage;
