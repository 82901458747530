import { combineReducers } from "redux";
import home from "./home";
import whatIsHumanTraffik from "./whatIsHumanTraffik";
import whatIsSexTraffik from "./whatIsSexTraffik";
import factAndStats from "./factAndStats";
import policyAndLegislation from "./policyAndLegislation";
import helpAndRecources from "./helpAndRecources";
import traffikAndSex from "./traffikAndSex";
import nationalDirectory from "./nationalDirectory";
import rescureStories from "./rescureStories";
import rescueStoryDetail from "./rescueStoryDetail";
import shareStory from "./shareStory";
import contactUs from "./contactUs";
import currentAffairs from "./currentAffairs";
import projectUnite from "./projectUnite";
import ourSponsors from "./ourSponsors";
import donateNow from "./donateNow";
import corporatePatners from "./corporatePatners";
import startAFundraiser from "./startAFundraiser";
import donatePay from "./donatePay";
import nearEvents from "./nearEvents";
import blogDetail from "./blogDetail";
import newsDetail from "./newsDetail";
import signUp from "./signUp";
import signIn from "./signIn";
import liveTV from "./liveTV";
import movieDetail from "./movieDetail";
import tvShowDetail from "./tvShowDetail";
import fetchSiteSettings from "./fetchSiteSettings";
import terms_and_conditions from "./terms_and_conditions";

export default combineReducers({
  home,
  whatIsHumanTraffik,
  whatIsSexTraffik,
  currentAffairs,
  factAndStats,
  nearEvents,
  newsDetail,
  blogDetail,
  donatePay,
  startAFundraiser,
  corporatePatners,
  donateNow,
  ourSponsors,
  projectUnite,
  policyAndLegislation,
  helpAndRecources,
  traffikAndSex,
  nationalDirectory,
  rescureStories,
  rescueStoryDetail,
  shareStory,
  contactUs,
  signUp,
  signIn,
  liveTV,
  movieDetail,
  tvShowDetail,
  fetchSiteSettings,
  terms_and_conditions

});
