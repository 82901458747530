import http from "../../helpers/http";

import {
    FETCH_LIVE_TV_CONTENT_SUCCESS,
    FETCH_LIVE_TV_CONTENT_FAILED
} from "../actions/actionTypes";

export const fetchData = () => (dispatch) => {
    http
        .get("live-tv")
        .then(({ data }) => {
            console.log(data)
            dispatch({
                type: FETCH_LIVE_TV_CONTENT_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_LIVE_TV_CONTENT_FAILED,
                payload: error
            });
        });
};
