import http from "../../helpers/http";
import { doObjToFormData } from "../../helpers/helpers";

import {
    FETCH_TV_SHOW_DETAIL_CONTENT_SUCCESS,
    FETCH_TV_SHOW_DETAIL_CONTENT_FAILED
} from "../actions/actionTypes";

export const fetchData = (slug) => (dispatch) => {
    http
        .post("tv-show-details", doObjToFormData({ slug: slug }))
        .then(({ data }) => {
            console.log(data)
            dispatch({
                type: FETCH_TV_SHOW_DETAIL_CONTENT_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_TV_SHOW_DETAIL_CONTENT_FAILED,
                payload: error
            });
        });
};
